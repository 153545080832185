import * as THREE from 'three'
import React, { useRef, useState, useMemo, useEffect, useContext } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Text, TrackballControls, OrthographicCamera, PerspectiveCamera } from '@react-three/drei'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {Context} from '../Store';
import { useNavigate  } from 'react-router-dom';
import Solutions from './Solutions'
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Trianglify from 'react-trianglify';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import '../styles/pulse.css'

const CameraController = ({zoom_in, setZoomIn}) => {
 const { camera, gl, target } = useThree();


 let controls = new OrbitControls(camera, gl.domElement);
 controls.enableZoom = false;
 const [step, setStep] = useState(0.05);
 const [stop, setStop] = useState(false);
 const [rotate, setRotate] = useState(true);
 
 useEffect(()=>{
  controls.autoRotate = true;
  controls.enableZoom = false;
  controls.update();
  if(zoom_in==true){
   setStop(false);
   
  }
 },[zoom_in])

 
 useFrame(()=>{
  
   controls.autoRotate = rotate;
   controls.enableZoom = false;
   if(zoom_in==true && !stop){
    //controls.autoRotate = false;
    setStep(0.6)

    
    if (camera.zoom > 500){
     
     setRotate(false)
     controls.enableZoom = false;
     controls.update();
 
      
     setStop(true);
     setZoomIn(false);
     camera.updateProjectionMatrix();
     
    } else{
     camera.position.x = camera.position.x-step
     camera.position.y = camera.position.y-step
     camera.position.z = camera.position.z-step
     camera.zoom = camera.zoom*1.04
     controls.enableZoom = false;
     camera.updateProjectionMatrix()
    }
    
    
    
    //controls.target.set(controls.target.x, controls.target.y, controls.target.z>0?controls.target.z-step:controls.target.z+step)
    //controls.update();
     
   }
   controls.enableZoom = false;
   controls.update();
 })

 useEffect(
   () => {
  
     controls.autoRotate = true;
     controls.enableZoom = false;
 
     return () => {
      controls.enableZoom = false;
       controls.update();
     };
   },
   [camera, gl]
 );
 return null;
};

const CameraController2 = ({zoom_in, setZoomIn, enableZoom, lerp}) => {
  const { camera, gl, target } = useThree();
 
 
  let controls = new OrbitControls(camera, gl.domElement);
  controls.enableZoom = false;
  const [step, setStep] = useState(-10);
  const [stop, setStop] = useState(false);
  const [rotate, setRotate] = useState(false);
  const vec = new THREE.Vector3();
  
  useEffect(()=>{
   controls.autoRotate = true;
   controls.enableZoom = false;
   controls.update();
   if(zoom_in==true){
    setStop(false);
    
   }
  },[zoom_in])


  useEffect(()=>{
   camera.lookAt(-100,-100, 0)
  },[])
 
  
  useFrame(()=>{
    
    if (stop){
     
      setRotate(false)
      controls.enableZoom = false;
      controls.update();
  
      if(camera.zoom > 500){
        setStop(true);
        setZoomIn(false);
      }
      
      camera.updateProjectionMatrix();
      
     } else{
      if(zoom_in){

        if(!lerp){
          camera.zoom = camera.zoom*1.04
        }
  
        
        controls.enableZoom = false;
        camera.updateProjectionMatrix()
      }
      
     }

    controls.autoRotate = rotate;
    controls.enableZoom = false;
    if(lerp){
      //console.log([camera.position.x,camera.position.y,camera.position.z+step])
      camera.lookAt(camera.position.x,camera.position.y,camera.position.z+step)
      if(camera.position.z <= 0){
        camera.position.x = 0
          camera.position.y = 0
          camera.position.z = 350
        camera.updateProjectionMatrix()
        
      }else{
        if(camera.position.z >= 300){
          setStep(-10)
        }
      }
      camera.position.lerp(vec.set(camera.position.x,camera.position.y,camera.position.z+step), .02)
    }
    controls.enableZoom = false;
    controls.update();
  })
 

  return null;
 };


const technlogies = [
 'React',
 'Node',
 'Python',
 'Pandas',
 'jQuery',
 'Flask',
 'FastAPI',
 'Firebase',
 'GCP',
 'JavaScript',
 'Next.js',
 'Tensorflow',
 'Machine Learning',
 'AI',
 'Forsta Surveys',
 'CSS',
 'three.js',
 'JSON',
 'HTML',
 'Stripe',
 'Mandrill',
 'MongoDB',
 'MySQL',
 
]



const useStyles = makeStyles({

  divider: {
    display: "block",
    width: "200px",
    background: "",
  },
  app: {
    background: 'transparent',
    minHeight: '100vh !important',
    padding: '20px',
    paddingTop: '100px',
  },
  sphere: {
    width: '100vw',
    height: '50vh'
  },
 

});


function Word({ children, ...props }) {
  
  const color = new THREE.Color()
  const fontProps = { font: '/Inter-Bold.woff', fontSize: 2.5, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false }
  const ref = useRef()
  const [hovered, setHovered] = useState(false)
  const over = (e) => (e.stopPropagation(), setHovered(true))
  const out = () => setHovered(false)
  // Change the mouse cursor on hover
  useEffect(() => {
    if (hovered) document.body.style.cursor = 'pointer'
    return () => (document.body.style.cursor = 'auto')
  }, [hovered])
  // Tie component to the render-loop
  useFrame(({ camera, clock }) => {
     
    // Make text face the camera
    ref.current.quaternion.copy(camera.quaternion)
    // Animate font color
    ref.current.material.color.lerp(color.set(hovered ? '#4593f5' : '#ffffff'), 0.1)
  })
  return <Text ref={ref} onPointerOver={over} onPointerOut={out} {...props} {...fontProps} children={children} onClick={()=>{props.onTechChange(props.ind)}}/>
}

function Cloud({ count, radius, onTechChange, zoom_in}) {
  // Create a count x count random words with spherical distribution
   
   
  
  
  const words = useMemo(() => {
    const temp = []
    const spherical = new THREE.Spherical()
    const phiSpan = Math.PI / (5 + 1)
    const thetaSpan = (Math.PI * 2) / 6
    let counter = 0;
    for (let i = 1; i < 6; i++){
     for (let j = 0; j < 6; j++) {

      temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), technlogies[counter]])
      counter+=1;
     }
     
    }
      // Taken from https://discourse.threejs.org/t/can-i-place-obects-on-a-sphere-surface-evenly/4773/6
      
    return temp
  }, [count, radius])
  return words.map(([pos, word], index) => <Word key={index} position={pos} children={word} ind={index} onTechChange={onTechChange}/>)
}


function randomNumber(min, max) { 
  return Math.random() * (max - min) + min;
}

function Dots(props) {
  const ref = useRef()
  
  const { vec, transform, positions } = useMemo(() => {
    const vec = new THREE.Vector3()
    const transform = new THREE.Matrix4()
    const positions = [...Array(100000)].map((_, i) => {
      const position = new THREE.Vector3()
      // Place in a grid
      position.x = randomNumber(-100, 100)
      position.y = randomNumber(-100, 100)
      position.z = randomNumber(-300, 300)

      // Offset every other column (hexagonal pattern)
      //position.y += (i % 2) * 0.5

      // Add some noise
      position.x += Math.random() * 0.3
      position.y += Math.random() * 0.3
      position.z += Math.random() * 0.3
      //position.y += Math.random() * 0.3
      return position
    })
    return { vec, transform, positions }
  }, [])
  useFrame(({ clock }) => {
    let scale = 1
    if(props.scale){
      scale = 1 + Math.sin(clock.elapsedTime) * 0.5
    }
    
    for (let i = 0; i < 10000; ++i) {
      vec.copy(positions[i]).multiplyScalar(scale)
      transform.setPosition(vec)
      ref.current.setMatrixAt(i, transform)
    }
    ref.current.instanceMatrix.needsUpdate = true
  })
  return (
    <instancedMesh ref={ref} args={[null, null, 10000]} onClick={()=>{props.onTechChange(props.ind)}}>
      <circleBufferGeometry args={[0.15]} />
      <meshBasicMaterial />
    </instancedMesh>
  )
}

function Universe() {
  const [state, dispatch] = useContext(Context);
  const [tr_height, setTRheight] = useState(100);
  const [tr_width, setTRwidth] = useState(100);
  const navigate = useNavigate();
  const classes = useStyles();
  const tr_container = useRef(null)
  const solutions = useRef(null)



  useEffect(()=>{
   console.log(state.currentTechnology)
  },[state.currentTechnology])

  const setZoomIn=(v)=>{
   dispatch({type: "SET ZOOM2", data: v})
  }

  useEffect(()=>{
    console.log(tr_container.current)
    if(tr_container.current !== null){
      console.log(tr_container.current);
      let dimensions = tr_container.current.getBoundingClientRect();
      console.log(dimensions)
      setTRheight(dimensions.height);
      setTRwidth(dimensions.width);
    }
    

  }, [tr_container])

  const onTechChange = (ind) => {
   //alert(ind)
   dispatch({type: 'SET TECHNOLOGY', data: ind});
   dispatch({type: 'SET ZOOM', data: true});
   dispatch({type: 'SET ZOOM2', data: true});
   setTimeout(() => {
    dispatch({type: 'SET ZOOM', data: false});
    navigate('/competences')
   }, 3000);
  }
  
  return (
    <div className={classes.app}>
    
   <div style={{
    width: '100vw',
    height: '100vh',
    position: 'fixed'
    
   }}>
    <div style={{
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: '+9',
    cursor: 'pointer'
   }}>
      <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 35], fov: 90 }} style={{zIndex: -1}}>
      <CameraController2 zoom_in={state.zoom2} setZoomIn={setZoomIn} enableZoom={false} lerp={false}/>
    <Dots onTechChange={onTechChange} scale={true}/>
    </Canvas>
    </div>
    
    <div style={{
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    zIndex: '+10',
    cursor: 'pointer'
   }}
   onClick={onTechChange}>
    
    </div>
    <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 35], fov: 90 }} style={{zIndex: -1}}>
      <fog attach="fog" args={['#000000', 0, 80]} />
      
      <Cloud radius={20} onTechChange={onTechChange} />
      
      <CameraController zoom_in={state.zoom_in} setZoomIn={setZoomIn} enableZoom={false}/>
       
    </Canvas>
   </div>
 
   <div
   style={{
    zIndex: '999',
    position: 'relative',
    //transform: 'translate(0,-20vh)',
    marginTop: '90vh',
    background: 'rgba(0,0,0,1)',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingBottom: '5vh',
    paddingTop: '5vh',
   }} ref={tr_container}>

      <span className="pulsingButton" onClick={(event)=>{solutions.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
            }); event.preventDefault(); event.stopPropagation(); event.stopImmediatePropagation(); }}><ArrowCircleDownIcon style={{fontSize: '100px'}}/></span>
   <div style={{
    position: 'relative',

   }}> 

    </div>
    <div ref={solutions}><Solutions /></div>
   
 
    </div>
   </div>
  )
}



export {Dots, CameraController2, Universe};