const Reducer=(state,action)=>{
 switch(action.type){
  case 'SET TECHNOLOGY':
   return{
    ...state,
    currentTechnology: action.data
   };
  case 'SET ZOOM':
   return{
    ...state,
    zoom_in: action.data
   };
   case 'SET ZOOM2':
   return{
    ...state,
    zoom2: action.data
   };
  default: return state;
 };
};

export default Reducer;