import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, Vector2 } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Section from '../Section';
import * as THREE from 'three';
import Grid from '@mui/material/Grid';

import techStack from '../resources/TechStack';
import {Dots, CameraController2} from './Universe'

import img_firebase from '../images/firebase.png';
import img_flask from '../images/flask.png';
import img_gcp from '../images/gcp.png';
import img_node from '../images/node.png';
import img_pandas from '../images/pandas.png';
import img_python from '../images/python.png';
import img_react from '../images/react.png';
import IvoCard from '../components/IvoCard'
import StackGrid from "react-stack-grid";


const useStyles = makeStyles({

  divider: {
    display: "block",
    width: "200px",
    background: "",
  },
  app: {
    background: 'transparent',
    minHeight: '100vh !important',
    padding: '20px',
    paddingTop: '100px',
  },
  sphere: {
    width: '100vw',
    height: '50vh'
  }


});

function Competences() {
  const classes = useStyles();
  const [tr_height, setTRheight] = useState(100);
  const [tr_width, setTRwidth] = useState(100);
  const tr_container = useRef(null);

  useEffect(()=>{
    console.log(tr_container.current)
    if(tr_container.current !== null){
      console.log(tr_container.current);
      let dimensions = tr_container.current.getBoundingClientRect();
      console.log(dimensions)
      setTRheight(dimensions.height);
      setTRwidth(dimensions.width);
    }
    

  }, [tr_container])

  useEffect(()=>{
  window.scrollTo(0,0);
  
  },[])
  return (
    <div className={classes.app}>
      <div style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        zIndex: '-1',
        cursor: 'pointer'
      }} ref={tr_container}>
      <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 300], fov: 90 }} style={{zIndex: -1}}>
        <CameraController2 zoom_in={false} setZoomIn={false} enableZoom={false} lerp={true}/>
        <Dots onTechChange={()=>{}}/>
      </Canvas>
      </div>
 
      <div style={{


    

    paddingTop: '5vh',
    zIndex: '1000',

   }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">

        {techStack.map((t,ind) => {
          return (
            <Grid item xs={12} md={6} lg={6}  xl={3}>
              <IvoCard header={t.name} img={t.img} num={ind}>
                {t.description()}
              </IvoCard>
            </Grid>
          
          )
        })}
      </Grid>
      </div>
      
    </div>
  );
}

export default Competences;





















