import { makeStyles } from '@mui/styles';

import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

/** Adding the animation modules  */
import { Parallax, Background} from 'react-parallax';
import { Fade, Slide } from "react-awesome-reveal";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({

 divider: {
   display: "block",
   width: "200px",
   background: "",
 },

 sectionContainer: {
  fontFamily: 'Roboto',
  fontSize: '18px',
  color: '#ffffff !important',
  background: 'rgba(0, 0, 0, .8)',
  //margin: '10px',
  //padding: '10px',
  textAlign: 'center',
  width: '100%'
 }

});


function Section({children, direction, heading}) {
 const classes = useStyles();
  return (
   <Slide delay={200} duration={500} direction={direction}>
    <div className={classes.sectionContainer}>
      <Grid container justifyContent="center" alignItems="center" spacing="3">
        <Grid item md={6} sm={6}>
          <span style={{margin: 'auto'}}><img src={children[0].props.src} width="300" height="300" style={{borderRadius: '150px', cursor: 'pointer'}} onClick={()=>{children[0].props.onClick()}}/></span>
          <h2>
            {heading}
          </h2>
        </Grid>
        <Grid item md={6} sm={6}>
          {children[1]}
        </Grid>
        <Grid item md={12} sm={12}>
          {children[2]}
        </Grid>
      </Grid>
     
      
       
     
    </div>
    </Slide>
  );
}

export default Section;