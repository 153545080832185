import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, Vector2 } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Section from '../Section';
import * as THREE from 'three';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Fade, Slide, Bounce } from "react-awesome-reveal";
function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
 
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {mesh.current.rotation.x += 0.01; mesh.current.rotation.y += 0.01; mesh.current.rotation.z += 0.01;})
  // Return view, these are regular three.js elements expressed in JSX

 
  return (
      <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.5 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}>
        <boxGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />

      </mesh>
  )
}

const useStyles = makeStyles({

  card: {
    background: 'rgba(0,0,0,.3) !important',
    border: '1px solid white',
    color: "#ffffff",
    //minHeight: '300px'
  },
  tech_card_header: {
   background: '#000000',
   transform: 'translate(0, -50%)',
   position: 'relative',
   fontFamily: "Roboto",
   padding: '5px'
  },
  img_tech: {
   height: '200px',
   width: 'auto',
   maxWidth: '100%',
   position: 'relative'
  },
  img_container: {
   textAlign: 'center'
  },
  info_div: {
   position: 'relative',
   top: 0,
   left: 0,
   zIndex: 10000,
   width: '100vw',
   height: '100vh',
   background: 'rgba(0,0,0,.5)'
  }


});

function TechCard({children,header,img, num}) {
 const classes = useStyles();
 const [open, setOpen] = useState(false);
  return (
   <Bounce delay={num*100} duration={1000} direction={'top'}>
    <Card className={classes.card}>
 
    <CardContent>
      <div className={classes.img_container}><img src={img} className={classes.img_tech}/></div>
    
      <Typography gutterBottom variant="h5" component="div">
        {header}
      </Typography>
       
    </CardContent>
    <CardActions>
     <Button variant="contained" onClick={()=>{setOpen(!open)}}>More</Button>
    </CardActions>
     
  </Card>
  <Dialog
        open={open}
        onClose={()=>{setOpen(!open)}}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{header}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(!open)}}>Close</Button>
        </DialogActions>
      </Dialog>
  
</Bounce>
  );
}

export default TechCard;
