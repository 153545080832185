import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useRef, useState, useEffect, Component } from 'react'
import { alpha, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Map from '../components/Map'
import axios from 'axios';
import Typing from "react-typing-animation";
import Card from "card-vibes";
import ParticleEffectButton from "react-particle-effect-button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../components/heading.css';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles({
    contactForm: {
        paddingTop: '90px',
    },
    planeImg: {
        width: '8%',
        transform: "rotate(-45deg)",
        color: 'white'
    },
    sendMsg: {
        height: '100px'
    },
    innerDiv: {
        width: '85%',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: '5%',
        marginLeft: '2%',
        //height: '100vh',
        background: 'rgba(0,0,0,.8)',
        transition: 'all .5s',
        border: '2px solid black',
        '&:hover': {
            background: 'rgba(0,0,0,.8)',
            //transform: 'scale(1.15)',
            border: '2px solid #ff0066'
        }
    },
    afterDiv: {
        width: '85%',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: '5%',
        marginTop: '10%',
        marginLeft: '2%',
        textAlign: 'center',
        background: 'rgba(0,0,0,.8)',
        transition: 'all .5s',
        border: '2px solid #ff0066',
    },
    mygrid: {
        background: 'rgba(0,0,0)',
    },
    submitButton: {
        background: 'black !important',
    },
    textFieldName: {
        paddingLeft: '0px !important',
        width: '95% !important',
        alignItems: 'left',
        backgroundColor: 'rgba(107,107,107,.3)'
    },
    textFieldMail: {
        paddingLeft: '0px !important',
        marginBot: '50px !important',
        width: '95% !important',
        backgroundColor: 'rgba(107,107,107,.3)'
    },
    textFields: {
        paddingLeft: '0px !important',
        marginBot: '50px !important',
        width: '95% !important',
        backgroundColor: 'rgba(107,107,107,.3)'
    },
    contactText: {
        paddingTop: '30px',
        display: 'block',
        //textAlign: 'center',
        color: "#ff0066",
        fontSize: '30px',
        fontWeight: '500',
    },
    contactTextSmall: {
        paddingBottom: '30px',
        display: 'block',
        textAlign: 'center',
        color: "#ff0066",
        fontSize: '30px',
        fontWeight: '500',
    },
    floatingLabelFocusStyle: {
        color: '#ff0066 !important'
    },
    introText: {
        fontSize: '50px',
        //color: '#FFD7EB',
        textAlign: 'center',
        marginBottom: '100px',
        userSelect: 'none'
    },
    card: {
        backgroundColor: 'rgba(107,107,107,.3) !important',
        textAlign: 'center',
        color: '#ff0066 !important',
        transition: 'all .9s',
        '&::before': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '0%',
            height: '100%',
            background: 'rgba(164, 0, 85, 0.8)',
            transition: 'all .9s',
            //borderRadius: '10rem',
            zIndex: -1,
        },
        '&:hover': {
            color: 'white !important',
            '&::before': {
                width: '100%',
            }
        }
    },
    sendIconImage: {
        transform: 'translate(0px,5px)'
    }
});


const location = {
    center: {
        lat: 42.696469663649225,
        lng: 23.32075406622211
    },
    zoom: 11
} // our location object from earlier


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#ff0066',
        fontSize: '1.3em',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(107,107,107,.1)',
        },
        '&:hover fieldset': {
            borderColor: '#ff0066',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ff0066',
        },
        '& .MuiInputBase-input': {
            color: 'lightgrey'
        },
    },
});


function ContactUs() {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [state, setState] = useState(false);
    const [hidden, setHidden] = useState(false);
    const playCardAnimation = () => { setState(!state) }

    const sendMe = () => {
        axios.post('https://serverkickit-xjjuskvurq-ew.a.run.app/send_message', {
            name: name, subject: subject, email: email, text: text
        }).then((res) => {
            console.log(res);
        })
    }

    return (
        <>
            <div className={classes.contactForm}>
                <div className="glow"><span className={classes.introText}>Let's make something awesome together.</span></div>
                <Grid container spacing={3} justifyContent="center" alignItems="center" className={classes.mygrid}>
                    <Grid item xs={11} md={6} xl={3} style={{ background: 'rgba(0,0,0,.8) !important' }}>
                        {hidden == false &&
                            <>
                                <ParticleEffectButton
                                    duration={3000}
                                    style="stroke"
                                    hidden={state}
                                    color="#ff0066"
                                    onComplete={() => setHidden(true)}
                                >
                                    <Typing speed={40} className={classes.contactTextSmall}>
                                        We'd love to hear from you and talk about your project!
                                    </Typing>
                                    <Typing speed={40} className={classes.contactTextSmall} startDelay={3000}>
                                        Use the form below to drop us an e-mail
                                    </Typing>
                                    <div className={classes.innerDiv}>
                                        <span className={classes.contactText}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={classes.planeImg}>
                                                <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                                            </svg>
                                            Contact Us
                                        </span>
                                        <Box component="form" noValidate autoComplete="off"
                                            sx={{
                                                '& > :not(style)': { m: 1 }, 
                                            }}>
                                            <CssTextField value={name} fullWidth InputLabelProps={{ className: classes.floatingLabelFocusStyle, }} className={classes.textFieldName} label="Name" variant="outlined" key="name" onChange={(event) => setName(event.target.value)} />
                                            <CssTextField value={email} fullWidth InputLabelProps={{ className: classes.floatingLabelFocusStyle, }} className={classes.textFieldMail} label="Email" variant="outlined" key="email" onChange={(event) => setEmail(event.target.value)} />
                                            <CssTextField value={subject} InputLabelProps={{ className: classes.floatingLabelFocusStyle, }} className={classes.textFields} label="Subject" variant="outlined" key="subject" onChange={(event) => setSubject(event.target.value)} />
                                            <CssTextField
                                                label="Message"
                                                multiline
                                                key="message"
                                                maxRows={10}
                                                className={classes.textFields}
                                                InputLabelProps={{ className: classes.floatingLabelFocusStyle, }}
                                                value={text}
                                                onChange={(event) => setText(event.target.value)}
                                            />
                                        </Box>
                                        <Card className={classes.card} onClick={()=>{playCardAnimation(); sendMe()}}>
                                            <span className={classes.sendMsg}>Send Message</span> <SendIcon className={classes.sendIconImage} />
                                        </Card>
                                    </div>
                                </ParticleEffectButton>
                            </>
                        }
                        {hidden &&
                            <div className={classes.afterDiv}>
                                <CheckCircleIcon style={{ color: 'rgba(164, 0, 85, 0.8)', fontSize: '300px', textAlign: 'center' }} /><br />
                                <span className={classes.contactTextSmall}>Message sent!</span>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={11} md={6} xl={9}>
                        <Map location={location} />
                    </Grid>
                </Grid>


            </div>

        </>
    )

}
//onClick={sendMe}
export default ContactUs;