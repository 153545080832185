import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';




import { Fade, Slide as RevealSlide, Bounce } from "react-awesome-reveal";


const useStyles = makeStyles({
    solutionContainer: {
        cursor: 'pointer',
        width: '100%',
        minHeight: '300px',
        background: 'rgba(107,107,107,.2)',
        transition: 'all .5s',
        '&:hover': {
            background: 'rgba(0,0,0,.6)',
            transform: 'scale(1.06)',
            border: '1px solid #ff0066'
        }
    },

    solutionInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '100%',
    },

    solutionCaption: {
        fontSize: '30px',
        color: "#ff0066",
        display: "block",
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontWeight: '800',
        textTransform: 'uppercase',
    },

    solutionDescription: {
        fontSize: '20px',
        color: 'white',
        display: "block",
        padding: '20px',
        textAlign: 'center',
    },
    solutionDetails: {
        display: 'block',

        width: '100%',
    },
    solutionInnerExpanded: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        background: 'red'
    },
    imgJumbo: {
        height: '50vh',
        width: '100%',
        background: 'red',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    bigTitle: {
        width: '50%',
        transform: 'translate(50%, -50%)',
        background: 'rgba(0,0,0,.9)',
        padding: '1vh',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '30px',
        color: "#ff0066",
        display: "block",
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Nunito',

    },
    bigText: {
        background: '#fff',
        color: 'rgb(107,107,107)',
        padding: '5vw'
    },
    leftImage: {
        width: '50%',
        minHeight: '50vh',
        float: 'left',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    dialog: {
        background: 'red !important',
        position: 'sticky !important',
 
        zIndex: '100000 !important'
    }
});

const directions = ['left', 'right', 'up', 'down']

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SolutionCard({ caption, text, description, onOpen }) {
    const classes = useStyles();

   




    return (
        <>
            <RevealSlide duration={1500} direction={directions[directions.length * Math.random() | 0]}>
                <div className={classes.solutionContainer} onClick={() => onOpen()}>
                    <div className={classes.solutionInner}>
                        <span className={classes.solutionCaption}>
                            {caption}
                        </span>
                        {false && 
                        <span className={classes.solutionDescription}>
                        {text}
                    </span>
                        }
                        
                    </div>

                </div>
            </RevealSlide>
            
        </>
    );
}

export default SolutionCard;