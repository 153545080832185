import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, Vector2 } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Section from '../Section';
import * as THREE from 'three';



const useStyles = makeStyles({

  footer: {
    background: 'rgba(0,0,0,.8) !important',
    fontSize: '24px',
    color: "#ffffff",
    padding: '15px',
    textAlign: 'right'
    //minHeight: '300px'
  },



});

function Footer({children,header,img, num}) {
 const classes = useStyles();

  return (
    <div className={classes.footer}>
        &copy; kickIT
    </div>
  );
}

export default Footer;
