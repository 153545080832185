import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import techStack from '../resources/TechStack';
import DialogContent from '@mui/material/DialogContent';
import { Parallax, Background } from "react-parallax";
import { Fade, Slide as RevealSlide, Bounce } from "react-awesome-reveal";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DialogActions from '@mui/material/DialogActions';



const useStyles = makeStyles({
    solutionContainerBig: {
        scrollBehavior: 'smooth',
        cursor: 'pointer',
        width: '100vw',
        height: '80vh',
        //paddingTop: '150px',
        background: 'rgba(107,107,107,.2)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',


    },

    solutionInner: {
        width: '60%',
        margin: 'auto',
        //float: 'left',
        height: 'auto',
        //backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1 ), rgba(12, 12, 12, .6))',
        "@media (max-width: 1000px)": {
            width: '90%',
        }
    },

    solutionCaption: {
        fontSize: '40px',
        color: "#ff0066",
        display: "block",
        //padding: '1vw',
        //width: '98vw',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontWeight: '800',
        textTransform: 'uppercase',
        background: 'black',
        position: 'sticky',
        
        //transform: 'translate(0, -100%)',
    },
    techCaption:{
        fontSize: '35px',
        color: "#ff0066",
        display: "block",
        padding: '20',

        textAlign: 'center',
        fontFamily: 'Nunito',
        fontWeight: '800',
        textTransform: 'uppercase',
        background: 'rgba(0,0,0,.1)',
        position: 'sticky',
    },

    solutionDescription: {
        fontSize: '30px',
        color: '#ff0066',
        display: "block",
        padding: '5%',
        textAlign: 'center',
        width: '90%',
        float: 'right',
        fontFamily: 'Roboto',
        background: 'rgba(0,0,0,.9)',
        height: 'auto',
        marginTop: '80vh',
        boxShadow: '0px 0px 10px #ff0066'
    },
    techDescription: {
        fontSize: '24px',
        color: 'lightgrey',
        display: "block",
        padding: '10%',
        textAlign: 'left',
        width: '80%',
        float: 'right',
        fontFamily: 'Roboto',
        background: 'rgba(0,0,0,.9)',
        boxShadow: '0px 0px 10px #ff0066'
    },
    solutionDetails: {
        display: 'block',

        width: '100%',
    },

    imgJumbo: {
        height: '50vh',
        width: '100%',
        background: 'red',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    bigTitle: {
        width: '50%',
        transform: 'translate(50%, -50%)',

        padding: '1vh',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '30px',
        color: "#ff0066",
        display: "block",
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Nunito',

    },
    bigText: {
        background: '#fff',
        color: 'rgb(107,107,107)',
        padding: '5vw'
    },
    leftImage: {
        width: '50%',
        minHeight: '50vh',
        float: 'left',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'left',
        borderRadius: '50%',

    },
    dialog: {
        background: 'rgba(0,0,0,.9) !important',
        position: 'absolute !important',
        width: '96vw',
        marginLeft: '2vw',
        paddingTop: '50px',
        top: 0,
        left: 0,
        zIndex: '100000 !important'
    },
    techImgContainer: {
        margin: '1vw',
        borderRadius: '10vh',
        transition: 'all 1s ease-out',
        textAlign: 'center',
        '&:hover': {
            background: 'rgba(212, 43, 122, 0.3)',
        }
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function SolutionCardBigV2({ caption, text, description, onOpen, onClose, img, techs }) {
    const classes = useStyles();
    const card = useRef(null);
    const [techCurrent, setTechCurrent] = useState(techStack[techs[0]]);
    const [techind, settechind] = useState(techs[0]);
    useEffect(() => {
        window.scrollTo(0,0)

    }, [])

    return (
 
             
            <>
             
            <span className={classes.solutionCaption} ref={card}>
            <DialogActions>
      
      <CloseIcon onClick={() => onClose()} style={{float: 'right', cursor: 'pointer', transform: 'scale(3)', margin: '10px'}}/>
</DialogActions>
                {caption} 
                
            </span>
            <Parallax strength={1200}>
            <Background >
                <div
                    style={{
                        backgroundImage: `url('${img}')`,
                    }}
                    className={classes.solutionContainerBig}
                >

                </div>
            </Background>
          
            
            

                <div >
                    
                    <RevealSlide duration={1500} delay={300} triggerOnce={true}>

                        <div className={classes.solutionInner} >


                            
                            <span className={classes.solutionDescription}>
                                <Fade duration={1000}>
                                    <span>{text}</span>
                                </Fade> 
                                
                            </span>
                            <div className={classes.solutionDescription} style={{marginTop: '10px', boxShadow: 'none', background: 'transparent', paddingTop:"10px", paddingBottom: '25px'}}>
                            <Bounce duration={1000} delay={500}><KeyboardDoubleArrowDownIcon style={{transform: 'scale(5)'}}/></Bounce>
                            </div>
                            
                            
                            
                                <Grid container alignItems="center" spacing={0} style={{background: 'black', width: '100%', marginLeft: '0px !important', boxShadow: '0px 0px 20px #ff0066'}}>
                                     
                                        {techs.map((tind) => {
                                            return (
                                                <Grid item sm={4} md={4} lg={2}>
                                                    <div className={classes.techImgContainer} onClick={() => { setTechCurrent(techStack[tind]); settechind(tind)}} style={{background: `${techind == tind? 'rgba(212, 43, 122, 0.3)': 'white'}`}}>
                                                        <img src={techStack[tind].img} style={{ width: '50%', padding: '10%' }} />
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                
                                </Grid>
                            
                        </div>
                    </RevealSlide>
                    {techCurrent != null &&
                        <RevealSlide duration={1500} delay={300} triggerOnce={true}>
                            <div className={classes.solutionInner}>
                                <span className={classes.techCaption}>
                                    {techCurrent.name}
                                </span>
                                <span className={classes.techDescription}>
                                    {techCurrent.description()}
                                </span>
                            </div>
                        </RevealSlide>
                    }
                  
                </div>

            

         
            </Parallax>
   </>
          
    
     
    );
}

export default SolutionCardBigV2;