import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
 
import techStack from '../resources/TechStack';


import { Fade, Slide as RevealSlide, Bounce } from "react-awesome-reveal";


const useStyles = makeStyles({
    solutionContainerBig: {
        scrollBehavior: 'smooth',
        cursor: 'pointer',
        width: '100vw',
        height: 'auto',
        minHeight: '100vh',
        paddingTop: '150px',
        background: 'rgba(107,107,107,.2)',
        transition: 'all .5s',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',

    },
    solutionContainerBigGradient: {
        //backgroundImage: 'linear-gradient(to right, rgba(212, 43, 122, 0.3), rgba(212, 43, 122, 0.6))',
        width: '100%',
        height: 'auto',
        backgroundSize: 'cover',

    },

    solutionInner: {
        width: '100%',
        float: 'left',
        height: '100%',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1 ), rgba(12, 12, 12, .6))',
    },

    solutionCaption: {
        fontSize: '60px',
        color: "#ff0066",
        display: "block",
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontWeight: '800',
        textTransform: 'uppercase',
    },

    solutionDescription: {
        fontSize: '40px',
        color: 'white',
        display: "block",
        padding: '5%',
        textAlign: 'center',
        width: '90%',
        float: 'right',
        fontFamily: 'Roboto',
        background: 'rgba(0,0,0, .6)',
    },
    techDescription: {
        fontSize: '40px',
        color: 'white',
        display: "block",
        padding: '10%',
        textAlign: 'left',
        width: '80%',
        float: 'right',
        fontFamily: 'Roboto',
        background: 'rgba(0,0,0, .6)',
    },
    solutionDetails: {
        display: 'block',

        width: '100%',
    },
 
    imgJumbo: {
        height: '50vh',
        width: '100%',
        background: 'red',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    bigTitle: {
        width: '50%',
        transform: 'translate(50%, -50%)',
        background: 'rgba(0,0,0,.9)',
        padding: '1vh',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '30px',
        color: "#ff0066",
        display: "block",
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Nunito',

    },
    bigText: {
        background: '#fff',
        color: 'rgb(107,107,107)',
        padding: '5vw'
    },
    leftImage: {
        width: '50%',
        minHeight: '50vh',
        float: 'left',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'left',
        borderRadius: '50%',

    },
    dialog: {
        background: 'red !important',
        position: 'sticky !important',

        zIndex: '100000 !important'
    },
    techImgContainer: {
        borderRadius: '10vh',
        transition: 'all 1s ease-out',
        '&:hover': {
            background: 'rgba(212, 43, 122, 0.3)',
        }
    }
});


function SolutionCardBig({ caption, text, description, onOpen, onClose, img, techs }) {
    const classes = useStyles();
    const card = useRef(null);
    const [techCurrent, setTechCurrent] = useState(null)

    useEffect(() => {
        if (card) {
            card.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                //inline: 'center'
            })
        }

    }, [card])




    return (
        <div className={classes.solutionContainerBig} ref={card} style={{ backgroundImage: `url('${img}')` }}>
             
                <Grid container spacing={1}>
                    <Grid item xl={6} lg={6} md={12} sm={12}>
                        <RevealSlide duration={1500} delay={300} triggerOnce={true}>
                            <div className={classes.solutionInner}>
                                <Button onClick={() => onClose()} variant='contained'><CloseIcon /></Button>
                                <span className={classes.solutionCaption}>
                                    {caption}
                                </span>

                                <span className={classes.solutionDescription}>
                           
                                        <span>{text}</span>
                               
                                </span>
                                <span className={classes.solutionDescription}>
                                    <Grid container spacing={3}>
                                        {techs.map((tind) => {
                                            return (
                                                <Grid item sm={4}>
                                                    <div className={classes.techImgContainer} onClick={()=>{setTechCurrent(techStack[tind])}}>
                                                        <img src={techStack[tind].img} style={{ width: '50%', padding: '10%' }} />
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </span>
                            </div>
                        </RevealSlide>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12}>
                        {techCurrent != null &&
                            <RevealSlide duration={1500} delay={300}  triggerOnce={true}>
                                <div className={classes.solutionInner}>
                                <span className={classes.solutionCaption}>
                                    {techCurrent.name}
                                </span>
                                <span className={classes.techDescription}>
                                    {techCurrent.description()}
                                </span>
                                </div>
                            </RevealSlide>
                        }

                    </Grid>
                </Grid>

            
        </div>
    );
}

export default SolutionCardBig;