import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import axios from 'axios'
import SolutionCard from '../components/Solution'
import SolutionCardBig from '../components/SolutionBig'
import SolutionCardBigV2 from '../components/SolutionBigV2'
import jumbo1 from '../images/jumbo1.jpg'
import jumbo2 from '../images/gcp_jumbo.png'
import jumbo3 from '../images/code.jpeg'
import jumbo4 from '../images/design.jpg'
import { Fade, Slide as RevealSlide, Bounce } from "react-awesome-reveal";
import solutions from '../resources/solutions'
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link,
 useParams,
 useNavigate 
} from "react-router-dom";

const directions = ['left', 'right', 'up', 'down']

  


  const useStyles = makeStyles({
    solutionContainer: {
        cursor: 'pointer',
        width: '100%',
        minHeight: '300px',
        background: 'rgba(107,107,107,.2)',
        transition: 'all .5s',
        '&:hover': {
            background: 'rgba(0,0,0,.6)',
            transform: 'scale(1.06)',
            border: '1px solid #ff0066'
        }
    },

    solutionInner: {
        margin: '10px',

    },
 
 
    imgJumbo: {
        height: '50vh',
        width: '100%',
        background: 'red',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    bigTitle: {
        width: '50%',
        transform: 'translate(50%, -50%)',
        background: 'rgba(0,0,0,.9)',
        padding: '1vh',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '30px',
        color: "#ff0066",
        display: "block",
        padding: '20px',
        textAlign: 'center',
        fontFamily: 'Nunito',

    },
    bigText: {
        background: '#fff',
        color: 'rgb(107,107,107)',
        padding: '5vw'
    },
    leftImage: {
        width: '50%',
        minHeight: '50vh',
        float: 'left',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },
    dialog: {
        background: 'red !important',
        position: 'sticky !important',
 
        zIndex: '100000 !important'
    },
    sectionTitle: {
        fontFamily: 'Nunito',
        textAlign: 'center',
        fontSize: '60px',
        color: 'white'
    },
    sectionSubtitle: {
        fontFamily: 'Nunito',
        textAlign: 'center',
        fontSize: '40px',
        color: 'white'
    },
    sectionHeader:{
        marginBottom: '3vh'
    },
    pinky: {
        color: '#ff0066'
    }
});
function SolutionDetails() {
    const classes = useStyles()
    const [open, setOpen] = useState(null);
    let { id } = useParams();

    const navigate = useNavigate();

  return (
  
    <div style={{paddingTop: '100px', background: 'black'}}>
                    <SolutionCardBigV2 
                    caption={solutions[id].topic} 
                    text={solutions[id].text} 
                    description={solutions[id].description} 
                    onOpen={()=>setOpen(id)} 
                    onClose={()=>{navigate(-1);}} 
                    img={solutions[id].img} 
                    techs={solutions[id].techs?solutions[id].techs:[]}
                />
               </div>
           
  );
}

export default SolutionDetails;