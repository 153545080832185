import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { useCookies } from 'react-cookie';
import { Grid } from '@mui/material';
import { textAlign } from '@mui/system';

const useStyles = makeStyles({
  details_container: {
    background: 'rgb(0,0,0,0.9)',
    width: '100%',
    height: '100%',
    color: "white",
    position: "fixed",
    top: 0,
    bottom: 0,
    zIndex: '999',
    overflow: 'auto',
    paddingTop: '90px'
  },
  details_multiline: {
    whiteSpace: 'pre-line',
    padding: "30px",
    background: 'black'
  },
  frame: {
    width: "100vw",
    height: "100vh"
  },
  header: {
    textAlign: 'left',
    padding: '20px'
  },
  closebtn: {
    float: 'right',
    display: 'inline-block !important',
    margin: '5px !important',
    background: 'red !important',
    color: "white !important"
  },
  style_name: {
    fontSize: '20px',
    color: '#4086f7',
    lineHeight: '50px'
  },
  location_div: {
    color: '#ffc800',
    fontFamily: "Roboto",
    fontSize: '18px',
    textAlign: 'center'
  }
})

const Details = ({ style, onClose }) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['style']);
  useEffect(() => {
    console.log({ cookies: cookies });
  }, [cookies])

  useEffect(() => {
    setCookie('style', style.name);
  }, [style.name])

  return (
    <div className={classes.details_container}>

      <div className={classes.header}>


        <Grid container>
          <Grid item xs={10}>
            <span className={classes.style_name}>{style.name}</span>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={onClose} className={classes.closebtn} variant="outlined">Close</Button>
          </Grid>
        </Grid>
      </div>

      <div className={classes.location_div}>
        {style.location}
      </div>

      <div className={classes.details_multiline}>
        {style.details}
      </div>
      
      <div className={classes.frame}>
        <iframe src={style.location} title={style.name} style={{ width: '100%', height: "90%" }} allow="geolocation"></iframe>
      </div>

    </div>
  )
}

export default Details;
