
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';


const useStyles = makeStyles({
 card: {
   background: '#1d242d',
   width: '100%',
   height: '100%',
   color: "white",
   border: '2px solid #ff0066'
 },
 platform_chip: {
  alignSelf: 'right',
  float: 'right',
  display: 'block'
 }
})

const Style = ({style, onSelect}) => {
 const classes = useStyles();
 return (
     <Card className={classes.card}>
      <CardMedia
        component="img"
        height="140"
        image={style.img}
        alt="Style preview image."
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {style.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         {style.description}
        </Typography>
      </CardContent>
      <CardActions style={{left: 0, bottom: 0, position: 'relative'}}>
        <Button size="small" onClick={onSelect} variant="contained">Learn More</Button>
        {style.platform && 
         <span className={classes.platform_chip}><Chip label="Platform required" color="success"/></span>
        }
      </CardActions>
    </Card>

 )
}


export default Style;
