import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, Vector2 } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Section from '../Section';
import TechCard from '../components/TechCard'
import * as THREE from 'three';
import Grid from '@mui/material/Grid';
import { Bounce, Slide, Fade, Zoom } from "react-awesome-reveal";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import img1 from '../images/Ivo.JPG'
import img2 from '../images/koce.JPG'

import back from '../images/asd.jpg'

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => { mesh.current.rotation.x += 0.01; mesh.current.rotation.y += 0.01; mesh.current.rotation.z += 0.01; })
  // Return view, these are regular three.js elements expressed in JSX


  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[2, 2, 2]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />

    </mesh>
  )
}

const useStyles = makeStyles({

  divider: {
    display: "block",
    width: "200px",
    background: "",
  },
  app: {
    //background: 'transparent',
    minHeight: '100vh !important',
    padding: '4vw',
    paddingTop: '20vh',
    backgroundImage: `url('${back}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  },
  span: {
    margin: '10px',
    padding: '10px',
    display: 'block'
  },
  image: {
    width: '90%',
    maxWidth: '500px',
    float: 'left',
    margin: '20px',
    cursor: 'pointer'
  },
  spanmail: {
    display: 'inline-block',

    textAlign: 'right',
    color: 'yellow',
    fontSize: "24px"
  },
  infoCard: {
    textAlign: 'left',
    background: "rgba(0,0,0,.5)",
    padding: '2%',
    fontSize: '16px',
    borderRadius: '20px'
  },
  fancyTitle: {
    color: '#ff0066',
    fontSize: '24px'
  },
  textContent: {
    color: "white"
  }


});

function About() {
  const classes = useStyles();
  const [deg, setDeg] = useState(0);
  const [open, setOpen] = useState(0)
  useEffect(() => {
    setInterval(() => {
      setDeg(deg + 3)
    }, 500)
    window.scrollTo(0, 0)
  }, [])
  return (

    <div className={classes.app}>
      {open == 1 &&
        <Dialog
          open={open}
          onClose={() => { setOpen(!open) }}
          scroll='body'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
          PaperProps={{
            style: {
              backgroundColor: 'rgba(12,12,12,0.1)',
              boxShadow: 'none',
              color: 'white',
              overflowX: 'hidden'
            },
          }}
        >


          <div style={{
            backgroundColor: 'rgba(12,12,12,0.9)',
            boxShadow: 'none',
            color: 'white'
          }}>
            <DialogActions>
              <CloseIcon onClick={() => setOpen(0)} style={{ float: 'right', cursor: 'pointer', transform: 'scale(3)', margin: '10px' }} />
            </DialogActions>
            <DialogTitle ><span className={classes.headerBig}>Konstantin Kosev</span></DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
              >
                <span className={classes.textContent}>
                  <div className={classes.infoCard}>
                    <span className={classes.span}>
                      I've started my career back in 2011, when my first employer was one of the 3 biggest telecom companies in Bulgaria - Vivacom. <br />
                      I was a trainee for several months and had been working on the internal portal, with focus on tools for sharing documents across teams and departments. <br />
                      That was the first time I had to deal with corporate scale Data Bases, containing thousands of tables and to aggregate data and reports iterating over them.
                    </span>
                    <span className={classes.span}>
                      Sadly for me, there wasn't an open position for my role after the training period, but with the knowledge and the experience gained there it was easy for me to find a tech job.
                    </span>
                    <span className={classes.span}>
                      That's how I ended up working in a marketing research agency, located in Sofia, Bulgaria. Bright MR has been my second home for almost 10 years now. During these years I've had many roles. I started like a junior survey developer, with focus on scripting basic questionnaires, with the usual conditional routing within the project.<br />
                      Later on I showed off my competences in custom scripting with jQuery, which was 'the real thing' back in those days. After that I started working for bigger clients, with special requirements regarding the look and feel of their projects.
                    </span>
                    <span className={classes.span}>
                      The complexity and the style requirements for the projects were going higher, I became senior scripter, started teaching young colleagues, travelling and meeting teammates from different countries and sharing knowledge.
                    </span>
                    <span className={classes.span}>
                      With all this industry knowledge, and with my enthusiasm for constant development, it soon became clear that the regular projects don't suit me well. <br />
                      Luckily, at that point the idea to form a Research and Development Team in our company emerged.
                    </span>
                    <span className={classes.span}>
                      So here I am today, part of the RDI team at Bright MR. After 3 years on this position I'm proud that we managed to develop some one-of-a-kind business solutions, giving our clients a wide variety of options to bring their user experience to another level, without compromising on data quality.
                    </span>
                    <span className={classes.span}>
                      Apart of that, I've been a major player in developing critical internal tools, that helped us to streamline many of the administrative tasks and to provide features for better tracking of costs and resources. Developing multiuser CMS tools, task tracking platforms, reporting and charting tools has been a big part of my daily duties.
                    </span>
                    <span className={classes.span}>
                      Now my colleagues and I are on our way to bring our knowledge to a wider audience that will be interested to use it.
                    </span>
                    <span className={classes.span}>
                      We will be happy to have you as our client if the Tech Stack that we provide is something that suits your needs.
                    </span>
                    <span className={classes.spanmail}>
                      konstantin.kosev.pro@gmail.com
                    </span>
                  </div>
                </span>
              </DialogContentText>
            </DialogContent>

          </div>



        </Dialog>
      }
      {open == 2 &&
        <Dialog
          open={open}
          onClose={() => { setOpen(!open) }}
          scroll='body'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
          PaperProps={{
            style: {
              backgroundColor: 'rgba(12,12,12,0.1)',
              boxShadow: 'none',
              color: 'white',
              overflowX: 'hidden'
            },
          }}
        >



          <div style={{
            backgroundColor: 'rgba(12,12,12,0.9)',
            boxShadow: 'none',
            color: 'white'
          }}>
            <DialogActions>
              <CloseIcon onClick={() => setOpen(0)} style={{ float: 'right', cursor: 'pointer', transform: 'scale(3)', margin: '10px' }} />
            </DialogActions>
            <DialogTitle ><span className={classes.headerBig}>Ivaylo Hristov</span></DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
              >
                <span className={classes.textContent}>
                  <div className={classes.infoCard}>
                    <span className={classes.span}>
                      I started as a project manager in 2014. In 2 years on this role, I managed to establish, develop, and maintain a positive business and customer relationships.
                      <br />
                      Yet, the more I worked with developers, the more I was amused of what they could do.
                      <br />
                      As a passionate personality, and dedicated to accomplish my goals, I started taking developer courses that included recorded auto-graded and peer-reviewed assignments, video lectures and more.
                    </span>
                    <span className={classes.span}>
                      This is how my developer career started in 2016.
                      <br />
                      During the interview, I was asked about my experience. Luckily for me I knew how to leverage the skills I acquired as a project manager and relate them to the role. And suddenly, it appeared to me that I had more experience than I thought.
                      <br />
                      Started as a junior survey developer, and in no time the hunger for knowledge drove me to insane projects, and it felt amazing to knock them out of the park, though they often came at a cost of my time.
                    </span>
                    <span className={classes.span}>
                      3 years later, as a senior developer already with an additional experience in creating and keeping deadlines, project management, database knowledge and familiarity with BRM and CRM practices I became a leading part of the team.
                    </span>
                    <span className={classes.span}>
                      I have a serious passion for UI effects, and creating intuitive, dynamic user experiences. One of my dreams is to master all the design technologies, and I am working towards it.
                    </span>
                    <span className={classes.span}>
                      To find out more about what we can do for you, get a hold of us by email.
                    </span>
                    <span className={classes.spanmail}>
                      hivailo.hristov@gmail.com
                    </span>
                  </div>
                </span>
              </DialogContentText>
            </DialogContent>

          </div>



        </Dialog>
      }
      <Grid container spacing={1} alignItems="center" justifyContent="center">




        <Grid item xl={6} sm={12}>
          <Section direction='right' heading="Konstantin Kosev" key="kk">
            <img src="https://storage.googleapis.com/kickit_static/about/KK.jpg" onClick={() => { setOpen(1); console.log('click') }} />
            <div className={classes.infoCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span className={classes.fancyTitle}>Key Competences</span>
                </Grid>
                <Grid item xs={12}>
                  Backend Scripting
                </Grid>
                <Grid item xs={12}>
                  Custom Frontend Development
                </Grid>
                <Grid item xs={12}>
                  Microservices
                </Grid>
                <Grid item xs={12}>
                  API Integrations
                </Grid>
                <Grid item xs={12}>
                  Online Payment Integrations
                </Grid>
                <Grid item xs={12}>
                  Forsta Surveys Development and Customization
                </Grid>
              </Grid>
            </div>


          </Section>
        </Grid>
        <Grid item xl={6} sm={12}>
          <Section direction='right' heading="Ivaylo Hristov" key="ih">
            <img src='https://storage.googleapis.com/kickit_static/about/IH.jpg' class={classes.image} onClick={() => { setOpen(2); console.log('click') }} />
            <div className={classes.infoCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span className={classes.fancyTitle}>Key Competences</span>
                </Grid>
                <Grid item xs={12}>
                  Backend Scripting
                </Grid>
                <Grid item xs={12}>
                  Custom Frontend Development
                </Grid>
                <Grid item xs={12}>
                  Microservices
                </Grid>
                <Grid item xs={12}>
                  API Integrations
                </Grid>
                <Grid item xs={12}>
                  Online Payment Integrations
                </Grid>
                <Grid item xs={12}>
                  Forsta Surveys Development and Customization
                </Grid>
              </Grid>
            </div>


          </Section>
        </Grid>


      </Grid>





      {false &&
        <Section direction='right' heading="Mihael Mihov">
          <img src="https://storage.googleapis.com/kickit_static/about/MM.jpg" class={classes.image} />
          <div className={classes.infoCard}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.fancyTitle}>Key Competences</span>
              </Grid>
              <Grid item xs={12}>
                Backend Scripting
              </Grid>
              <Grid item xs={12}>
                Custom Frontend Development
              </Grid>
              <Grid item xs={12}>
                Microservices
              </Grid>
              <Grid item xs={12}>
                API Integrations
              </Grid>
              <Grid item xs={12}>
                Online Payment Integrations
              </Grid>
              <Grid item xs={12}>
                Forsta Surveys Development and Customization
              </Grid>
            </Grid>
          </div>
          <div className={classes.infoCard}>
            <span className={classes.span}>
              I started my career in 2011 as a junior scripter, working for Bright Marketing Research. The five years I spent there saw me go from a juniour position to a senior one. I was involved in custom developments and learned something new every day. Working with databases and writing in python, with the occasional custom visualisation with html/css and jquery.
              We were always trying to improve the quality as well, so a lot of good work practices were learned.
              Working there gave me the opportunity to also learn how to work best in a team and I also learned good team leading practices, having the chance to be appointed as a shift leader for the team for long periods of time.
              Time and workload management are just some of the skills I learned working for BMR.
            </span>
            <span className={classes.span}>
              After the five years at BMR, with all the knowledve and experience, I was able to start in one of the world's top marketing firms - IPSOS, in 2016.
              Starting at first as a standard scripter, and raising to an expert scriptwriter position in the span of 4 years. Using visual basic, jquery, and working with big clients and on some of the biggest and most challanging projects for the firm.
              After 5 years as a scripter I was given the opportunity to move to a development position in the consultancy department of the firm, with a fulltime writing in javascript. Immediately I was appointed as the owner of a product that needed to be developed for use for multiple countries, with a lot of custom functionalities, based on the marketing methodologies used in each region.
              For the last 6 years I learned a lot and got a better grasp on how to take care of a client's needs and have a positive and also productive conversations with them.
            </span>
            <span className={classes.span}>
              I love what I do and all the experience gained. In my spare time I like to listen to music and go hiking.
              Nothing clears a head like a long drive with a great soundtrack and good company.
            </span>
            <span className={classes.span}>
              If you want to know more and are interested in what we can do for you to reach your goals, contact us:<br />
              <span className={classes.spanmail}>mihael.p.mihov@gmail.com</span>
            </span>

          </div>
        </Section>
      }


    </div>
  );
}

export default About;
