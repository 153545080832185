import logo from './logo.svg';
import './App.css';
import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, Vector2 } from '@react-three/fiber'

import Section from './Section';
import * as THREE from 'three';
import About from './pages/About';
import {Universe} from './pages/Universe';
import Competences from './pages/Competence';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Sphere from './components/TechSphere';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import SolutionDetails from './pages/SolutionDetails';
import All from './bmrstyles/all';
import GridExample from './pages/GridExample';

import Trianglify from 'react-trianglify';

import Store, { Context } from './Store';
import triangles from './images/triangles.svg';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Outlet,
  useLocation,
  Navigate
} from "react-router-dom";
 
    

const useStyles = makeStyles({

  divider: {
    display: "block",
    width: "200px",
    background: "",
  },
  trianglify: {
    width: '100vw',
    height: '100vh',
    zIndex: '-3',
    position: 'fixed',
    backgroundImage: `url('${triangles}')`,
    backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
  },
  shadow: {
    background: "rgba(0,0,0,.7)",
    width: '100%',
    height: '100%',
    zIndex: '-2',
    position: 'fixed'
  },
  sphere: {
    width: '100%',
    height: '100%',
    zIndex: '-1',
    position: 'fixed'
  },


});

function App() {
  const classes = useStyles();
  const location = useLocation();

  return (
  <Store>
      <NavBar />
      
      <div className={classes.trianglify}>

  
      </div>
 
      {(['', '/', '/universe'].includes(location.pathname) || true) &&
        <div className={classes.shadow}>
          
        </div>
      }
 
      <Routes>
        <Route path="/" element={<Universe />} />
        <Route path="/universe" element={<Universe />} />
        <Route path="/about" element={<About />} />
        <Route path="/competences" element={<><Competences /></>} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gridExample" element={<GridExample />} />
        <Route path="/bmrstyles" element={<All />} />
        <Route path="/solutionDetails/:id" element={<SolutionDetails />} />
      </Routes>
      <Footer />
 </Store>
  );
}

export default App;
