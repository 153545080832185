const techStack = [
    {
        //0
        name: 'Firebase',
        img: 'https://storage.googleapis.com/kickit_static/techstack/firebase.png',
        description: () => {
            return (
                <div>
                    Firebase is a platform developed by Google for creating mobile and web applications.<br />
                    <br />
                    It's scalability and monitoring tools make it a preferred choice for deployment of medium to large scale products<br />
                    <br />
                    Apart of the integrated build and deployment tools that come with it, Firebase is a gamechanger once you need real-time messaging. With its Realtime DB and access to user device data, functions like real time push notifications and in-app messages become an easy task to complete.<br />
                    <br />
                    As long as you have your production ready frontend bundle (technology doesn't really matter) you are ready to deploy with ease.
                </div>
            )
        }
    },
    {
        //1
        name: 'Flask',
        img: 'https://storage.googleapis.com/kickit_static/techstack/flask.png',
        description: () => {
            return (
                <div>
                    Flask is a lightweight WSGI web application framework. It is designed to make getting started quick and easy, with the ability to scale up to complex applications.<br />
                    <br />
                    For the past years it became a preferred choice for us as long as it comes to building backend services and APIs, since it's pretty lightweight and yet scalable and powerful. Written in Python it suits the rest of the technology stack that we use in order to cover all possible client needs.
                </div>
            )
        }
    },
    {
        //2
        name: 'Google Cloud Platform',
        img: 'https://storage.googleapis.com/kickit_static/techstack/gcp.png',
        description: () => {
            return (
                <div>
                    Google Cloud Platform (GCP) is a portfolio of cloud computing services that grew around the initial Google App Engine framework for hosting web applications from Google’s data centres. Since the launch of Google App Engine in 2008, GCP has grown into one of the premier cloud computing platforms on the market.<br />
                    <br />
                    It will be pointless to list all the tools that the platform offers for any-scale business, but it's worth mentioning that for most of the common computing and data transfers over the internet you probably won't even be charged, as it comes with a free tier.<br />
                    <br />
                    If you're browsing bigger, things like cloud computing and databases, file storages and remote build tools with version control may be a huge benefit in terms of speed-up and cost efficiency.<br />
                    <br />
                    The most common scenario we use the platform for is for deploying our backend services and running them in secure and scalable infrastructure. As it comes with lots of APIs itself, and has many ready to use modules for the most of the more common languages (including Python), it's a one-stop solution for deploying computational-heavy services.<br />
                    <br />
                    An exotic feature is the machine learning and AI tier of products that it offers and can be used to boost up innovative companies that are eager to provide better UX based on collected data. And that's a topic we are interested in as well.
                </div>
            )
        }
    },
    {
        //3
        name: 'Node',
        img: 'https://storage.googleapis.com/kickit_static/techstack/node.png',
        description: () => {
            return (
                <div>
                    Node. js (Node) is an open source development platform for executing JavaScript code server-side. Node is useful for developing applications that require a persistent connection from the browser to the server and is often used for real-time applications such as chat, news feeds and web push notifications.
                </div>
            )
        }
    },
    {
        //4
        name: 'Pandas',
        img: 'https://storage.googleapis.com/kickit_static/techstack/pandas.png',
        description: () => {
            return (
                <div>
                    Pandas is a Python package providing fast, flexible, and expressive data structures designed to make working with “relational” or “labelled” data both easy and intuitive.
                    <br /><br />
                    It is mainly used for data analysis and associated manipulation of tabular data in Dataframes. It aims to be the fundamental high-level building block for doing practical, real-world data analysis and allows importing data from various file formats such as comma-separated values, JSON, Parquet, SQL database tables or queries, and Microsoft Excel.
                    <br /><br />
                    <b>What Can You Do With DataFrames Using Pandas?</b>
                    <br />
                    <ul>
                        <li>Data cleansing</li>
                        <li>Data fill</li>
                        <li>Data normalisation</li>
                        <li>Merges and joins</li>
                        <li>Data visualisation</li>
                        <li>Statistical analysis</li>
                        <li>Data inspection</li>
                        <li>Loading and saving data</li>
                    </ul>
                    In fact, with Pandas, you can do everything that makes world-leading data scientists vote Pandas as the best data analysis and manipulation tool available.

                </div>
            )
        }
    },
    {
        //5
        name: 'Python',
        img: 'https://storage.googleapis.com/kickit_static/techstack/python.png',
        description: () => {
            return (
                <div>
                    Python is an interpreted, object-oriented, high-level programming language with dynamic semantics.
                    <br /><br />
                    Its high-level built in data structures, combined with dynamic typing and dynamic binding, make it very attractive for Rapid Application Development, as well as for use as a scripting or glue language to connect existing components together.
                    <br /><br />
                    <b>What can you do with python?</b>
                    <br />
                    <ul>
                        <li>Data analysis and machine learning</li>
                        <li>Web development</li>
                        <li>Automation or scripting</li>
                        <li>Software testing and prototyping</li>
                    </ul>
                </div>
            )
        }
    },
    {
        //6
        name: 'React',
        img: 'https://storage.googleapis.com/kickit_static/techstack/react.png',
        description: () => {
            return (
                <div>
                    The world cannot live without mobile and web applications in this day and age. Everything is digitised, from booking cabs to ordering food to make bank transactions. Thanks to the efficient frameworks that provide a seamless user experience. One such robust frontend library is React.
                    <br /><br />
                    React is a JavaScript-based UI development library. It is widely used in web development. React offers various extensions for entire application architectural support, such as Flux and React Native, beyond mere UI.
                    <br /><br />
                    React’s popularity today has eclipsed that of all other front-end development frameworks. <b>Here is why:</b>
                    <ul>
                        <li>Easy creation of dynamic applications</li>
                        <li>improved performance:</li>
                        <li>Unidirectional data flow</li>
                        <li>It can be used for the development of both web and mobile apps</li>
                        <li>Data Binding</li>
                    </ul>
                </div>
            )
        }
    },
    {
        //7
        name: 'Mongo DB',
        img: 'https://storage.googleapis.com/kickit_static/techstack/mongo.png',
        description: () => {
            return (
                <div>
                    MongoDB Atlas is a multi-cloud database service by the same people that build MongoDB.
                    Atlas simplifies deploying and managing your databases while offering the versatility you
                    need to build resilient and performant global applications on the cloud providers of your choice.<br />
                    <br />

                    With MongoDB Atlas as your data platform, your operational focus can shift away from the mundane operational
                    tasks and workflows required to build and maintain database infrastructure, allowing you to focus on helping
                    engineers add value to the business. Instead of maintaining hardware and keeping up with operating system-level
                    software patches, engineers can devote their time and energy to developing data models that meet the current
                    and future requirements of your enterprise.
                </div>
            )
        }
    },
]

export default techStack;