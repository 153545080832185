const solutions = [
 {
   topic: 'Content Management Systems',
   text: 'Custom, fine-tailored content management tools for your site.',
   img: 'https://storage.cloud.google.com/kickit_static/content_management_systems2.jpg',
   techs: [6,5,7]
 },
 {
   topic: 'Adaptive design',
   text: 'Mobile first, adaptive and responsive front-end components.',
   img: 'https://storage.googleapis.com/kickit_static/new_images/idk_new.png',
   techs: [6]
 },
 {
   topic: 'Data management and aggregation',
   text: 'Data management and reporting tools based on your own requirements.',
   img: 'https://storage.cloud.google.com/kickit_static/new_images/data_new.png',
   techs: [2,4,5,7]
 },
 {
   topic: 'Cloud Infrastructure and micro services',
   text: 'Let us take you to the clouds! We run our code on Google Cloud platform, keeping it safe and easily manageable.',
   img: 'https://storage.cloud.google.com/kickit_static/new_images/cloud_new.jpeg',
   techs: [0,2]
 },
 {
   topic: 'Dashboards and reporting tools',
   text: 'Give us the data and the insight you want to receive. We do the rest, with all the reporting that you need.',
   img: 'https://storage.cloud.google.com/kickit_static/new_images/dashboard_new.jpg',
   techs: [4,5,6,1]
 },
 {
   topic: 'Custom frontend components',
   text: 'Looking for fancy frontend? Share your idea and we will bring it to life with some of the first-class frameworks on the market.',
   img: 'https://storage.cloud.google.com/kickit_static/adaptive_design5.png',
   techs: [6]
 },
 {
   topic: 'Google Cloud AI services and machine learning',
   text: 'Looking to innovate and develop further? With our machine learning and AI stack we can help your software to adapt and learn based on your users actions.',
   img: 'https://storage.googleapis.com/kickit_static/new_images/idk_new2.png',
   techs: [0,2,5]
 },
 {
   topic: 'Scalability and cost efficiency',
   text: "Doing it in the cloud is better! With its pay as you go policy, you no longer need dedicated, and most of the time unused resources.",
   img: 'https://storage.cloud.google.com/kickit_static/new_images/idk_new2.png',
   techs: [2]
 }
]

export default solutions;