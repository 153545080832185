import { makeStyles } from '@mui/styles';
import React, { useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import axios from 'axios';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormHelperText from '@mui/material/FormHelperText';
import Typing from "react-typing-animation";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const useStyles = makeStyles({
    gridContainer: {
        paddingTop: '100px',
        paddingLeft: '1%',
        paddingRight: '1%',
        color: '#ff0066 !important',
        minHeight: '90vh'
    },
    myCardSelected: {
        background: 'rgba(0,0,0,.8) !important',
        border: '2px solid #ff0066 !important',
        paddingBottom: '2%',
        marginBottom: '30px !important'
    },
    contentText: {
        color: '#ff0066 !important',
        fontSize: '1.7em',
        background: 'rgba(41, 41, 41)',
        wordWrap: 'break-word',
        whiteSpace: 'pre-line !important'
    },
    cardButtons: {
        color: '#ff0066 !important',
        fontSize: '1.5em !important',
        paddingLeft: '30px !important',
        paddingRight: '30px !important',
        border: '1px solid rgba(0,0,0,.0) !important',
        '&:hover': {
            border: '1px solid #ff0066 !important',
        }
    },
    helperTextField: {
        color: '#ff0066 !important',
        fontSize: '1em !important'
    },
    floatingLabelFocusStyle: {
        color: '#ff0066 !important'
    },
    contactTextSmall: {
        paddingBottom: '30px',
        display: 'block',
        textAlign: 'center',
        color: "#ff0066",
        fontSize: '30px',
        fontWeight: '500',
    },
    editField: {
        width: '80% !important'
    },
    deleteButton: {
        textAlign: 'center !important',
        justifyContent: 'center !important'
    }
    // dialogMessage: {
    //     color: 'rgba(12,12,12,0.9) !important'
    // }
});


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#ff0066',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            //borderColor: 'rgba(107,107,107,.1)',
        },
        '&:hover fieldset': {
            borderColor: '#ff0066',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ff0066',
        },
        '& .MuiInputBase-input': {
            color: 'lightgrey',
            fontSize: '1.3em'
        },
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MyCard(props) {
    const classes = useStyles();
    const [val, setVal] = useState([])
    const [editInd, setEditInd] = useState(-1)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const contents = props.article.content
    const addItemToList = () => {
        contents.push(val)
        axios.post('https://serverkickit-xjjuskvurq-ew.a.run.app/update_article', { id: props.article._id.$oid, text: contents }).then((res) => {
            props.whenUpdated()
        })
    }
    const updateMe = () => {
        contents[editInd] = val
        axios.post('https://serverkickit-xjjuskvurq-ew.a.run.app/update_article', { id: props.article._id.$oid, text: contents }).then((res) => {
            props.whenUpdated()
            setEditInd(-1)
            setVal('')
        })
        console.log(contents)
    }
    const removeMe = () => {
        console.log(props.article._id.$oid)
        axios.post('https://serverkickit-xjjuskvurq-ew.a.run.app/delete_article', { id: props.article._id.$oid }).then((res) => {
            props.whenUpdated()
        })
    }

    return (
        <Grid item xl={props.size}>
            <Card sx={{ minWidth: 275 }} className={classes.myCardSelected}>
                <CardContent className={classes.contentText}>
                    {props.article.title}<br />
                    {contents.map((c, ind) => {
                        return (
                            <>
                                {ind == editInd &&
                                    <>
                                        <CssTextField multiline maxRows={10} className={classes.editField} type="text" onChange={(event) => setVal(event.target.value)} value={val} />
                                        <Button style={{ color: 'white' }} onClick={updateMe}><SaveIcon fontSize='large' /></Button>
                                    </>
                                }
                                {ind != editInd &&
                                    <CardActions>
                                        <Button onClick={() => { setEditInd(ind); setVal(c) }} style={{ color: `${ind == editInd ? 'red' : "white"}` }}><EditIcon /></Button>{c}
                                    </CardActions>
                                }
                            </>
                        )
                    })}
                    {editInd == -1 &&
                        <CssTextField multiline maxRows={10} fullWidth type="text" onChange={(event) => setVal(event.target.value)} value={val} />
                    }

                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                    <Button className={classes.cardButtons} size="small" onClick={addItemToList}>Add Item</Button>
                    <Button className={classes.cardButtons} size="small" onClick={handleClickOpen}>Remove</Button>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        className={classes.dialogMessage}
                        PaperProps={{
                            style: {
                              backgroundColor: 'rgba(12,12,12,0.8)',
                              boxShadow: 'none',
                              color: 'white'
                            },
                          }}
                    >
                        <DialogTitle>{"Please confirm deletion"}</DialogTitle>
                        <DialogContent >
                            <DialogContentText>
                                <span style={{color: 'white'}}>This action will remove your list with all items included in it.</span>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.deleteButton}>
                            <Button className={classes.cardButtons} onClick={handleClose}>Disagree</Button>
                            <Button className={classes.cardButtons} onClick={() => {removeMe();handleClose()}}>Agree</Button>
                        </DialogActions>
                    </Dialog>
                </CardActions>
            </Card>
        </Grid>
    );
}

function GridExample() {
    const classes = useStyles();
    const [current, setCurrent] = useState(null);
    const [articles, setArticles] = useState([]);
    const [titleval, setTitleVal] = useState([]);
    const [contentval, setContentVal] = useState([]);


    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const dynamicStyles = {
        ...matchesSM && {},
        ...matchesMD && { transform: 'translate(0px,5vh) !important' }
    }

    const whenClicked = (ind) => {
        setCurrent(ind);
    }

    const loadArticles = () => {
        axios.get('https://serverkickit-xjjuskvurq-ew.a.run.app/get_list').then((res) => {
            setArticles(res.data)
        })
    }

    const createNewArticle = () => {
        axios.post('https://serverkickit-xjjuskvurq-ew.a.run.app/add_article', {
            title: titleval,
            content: [contentval]
        }).then((res) => {
            loadArticles();
        })
    }

    useEffect(() => {
        loadArticles()
    }, [])

    return (
        <div className={classes.gridContainer}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} sx={{ ...dynamicStyles }}>
                    <div style={{ background: 'rgba(0,0,0,.8)', }}>
                        <Typing speed={50} className={classes.contactTextSmall}>
                            Welcome to the diary demo!
                        </Typing>
                        <Typing speed={50} className={classes.contactTextSmall} startDelay={1800}>
                            Here you can create a diary of your own and add items to it.
                        </Typing>
                    </div>
                    <Card sx={{ minWidth: 275 }} className={classes.myCardSelected}>
                        <CardContent className={classes.contentText}>
                            <FormHelperText className={classes.helperTextField}>Name your diary</FormHelperText>
                            <CssTextField
                                InputLabelProps={{ className: classes.floatingLabelFocusStyle, }}
                                label="Please type in"
                                multiline
                                maxRows={10}
                                type="text"
                                fullWidth
                                value={titleval}
                                onChange={(event) => { setTitleVal(event.target.value) }} />
                            <FormHelperText className={classes.helperTextField}>Add items to your diary</FormHelperText>
                            <CssTextField
                                InputLabelProps={{ className: classes.floatingLabelFocusStyle, }}
                                label="Please type in"
                                multiline
                                maxRows={10}
                                type="text"
                                fullWidth
                                value={contentval}
                                onChange={(event) => { setContentVal(event.target.value) }} />
                        </CardContent>
                        <CardActions style={{ justifyContent: 'center' }}>
                            <Button className={classes.cardButtons} onClick={createNewArticle} variant='outlined'>Add Article</Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} >
                    <Grid container spacing={4}>
                        {articles.map(((a, index) => {
                            return (
                                <MyCard size={11} article={a} id={index} whenClicked={whenClicked} selected={index == current ? true : false} whenUpdated={loadArticles} />
                            )
                        }))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default GridExample;