import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { makeStyles } from '@mui/styles';
import { Bounce, Slide, Fade, Zoom } from "react-awesome-reveal";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';



const useStyles = makeStyles({

    card: {
        background: 'rgba(0,0,0,0) !important',
        border: 'none',
        color: "#ffffff",
        //minHeight: '300px',
        transition: 'all .5s ease-out',
        "&:hover": {
            cursor: 'pointer',
            transform: 'scale(1.05)',
            transition: 'all .5s ease-out',
        }
    },
    img_tech: {
        transition: 'all .5s ease-out',
        cursor: 'pointer',
        transform: "scale(1.1)",
        '&:hover': {
            transform: "scale(1.3)",
            transition: 'all .5s ease-out',
        }
    },
    img_container: {
        textAlign: 'center'
    },
    header: {
        textAlign: 'center',
        color: 'white'
    },
    moreInfo: {
        color: 'white'
    },
    headerBig: {
        color: '#ff0066',
        fontSize: '40px',
        padding: '20px'
    },
    textContent: {
        color: 'white',
        fontSize: '26px',
        padding: '20px',
        
        "@media (max-width: 1000px)": {
            fontSize: '18px',
            overflow: 'hidden'
        }
    },
    vertCenter: {
        position: 'absolute',
        top: '50%',
        
        transform: 'translate(0, -50%)',
    }
});


function RecipeReviewCard({ children, header, img, num }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const [open, setOpen] = React.useState(false);
 
 
    return (
<>

        {open && 
        
       
           <Dialog
       open={open}
       onClose={()=>{setOpen(!open)}}
       scroll='body'
       aria-labelledby="scroll-dialog-title"
       aria-describedby="scroll-dialog-description"
       maxWidth="md"
       PaperProps={{
           style: {
             backgroundColor: 'rgba(12,12,12,0.1)',
             boxShadow: 'none',
             color: 'white',
             overflow: 'hidden'
           },
         }}
     >
        <Fade duration={1000}>
       <Zoom duration={1000}>
           <div style={{
             backgroundColor: 'rgba(12,12,12,0.9)',
             boxShadow: 'none',
             color: 'white'
           }}>
               <DialogActions>
     
               <CloseIcon onClick={() => setOpen(!open)} style={{float: 'right', cursor: 'pointer', transform: 'scale(3)', margin: '10px'}}/>
     </DialogActions>
               <DialogTitle ><span className={classes.headerBig}>{header} </span></DialogTitle>
       <DialogContent>
          
           <span className={classes.textContent}>{children}</span>
         
       </DialogContent>
       
           </div>
       
       </Zoom>
       </Fade>
     </Dialog>
      
           }

        <Bounce delay={num * 100} duration={1000} direction={'top'}>
             
            <div style={{textAlign: 'center', zIndex: '1000', width: '200px', height: '200px', borderRadius: '150px', background: "rgba(255,255,255,0.6)", margin: "auto"}}> 
                <div className={classes.vertCenter}><img src={img} width={200} onClick={()=>setOpen(true)}  style={{verticalAlign: "middle"}} className={classes.img_tech}/></div>
            </div>
      
            
             
            
        </Bounce>
        </>
    );
}

export default RecipeReviewCard;