import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { makeStyles } from '@mui/styles';
import { useScrollPosition, useScrollXPosition, useScrollYPosition } from 'react-use-scroll-position';
 
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link,
 useRouteMatch,
 useParams
} from "react-router-dom";

const pages = ['Our Universe', 'The Team', 'Tech Stack', 'Projects', 'Grid Demo', 'BMR styles', 'Contact Us'];

const routes = ['/universe', '/about', '/competences', '/projects', '/gridExample', '/bmrstyles', '/contact'];
 

const useStyles = makeStyles({

  divider: {
    display: "block",
    width: "200px",
    background: "",
  },
  app: {
    
  },
  myLinkV2: {
    textDecoration: 'none',
    color: 'black',
    padding: '.75rem 1.25rem',
    borderRadius: '10rem',
    textTransform: 'uppercase',
    fontSize: '1rem',
    letterSpacing: '.15rem',
    transition: 'all .3s',
    position: 'relative',
    overflow: 'hidden',
    margin: '10px',
    zIndex: 1,
    '&::after': {
     content: '""',
     position: 'absolute',
     bottom: '0',
     left: '0',
     width: '100%',
     height: '100%',
     background: 'transparent',
     //borderRadius: '10rem',
     zIndex: -2,
    },
    '&::before': {
     content: '""',
     position: 'absolute',
     bottom: '0',
     left: '0',
     width: '0%',
     height: '100%',
     background: '#007a99',
     transition: 'all .3s',
     //borderRadius: '10rem',
     zIndex: -1,
    },
    '&:hover': {
     color: '#fff',
     '&::before': {
       width: '100%',
     }
    }
  },
  myLink: {
   textDecoration: 'none',
   fontFamily: 'Nunito',
   padding: '.75rem 1.25rem',
   borderRadius: '10rem',
   color: '#fff',
   textTransform: 'uppercase',
   fontSize: '1rem',
   letterSpacing: '.15rem',
   transition: 'all .3s',
   position: 'relative',
   overflow: 'hidden',
   margin: '10px',
   zIndex: 1,
   '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'transparent',
    //borderRadius: '10rem',
    zIndex: -2,
   },
   '&::before': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '0%',
    height: '100%',
    background: '#DBCACA',
    transition: 'all .3s',
    //borderRadius: '10rem',
    zIndex: -1,
   },
   '&:hover': {
    color: '#4D1638',
    '&::before': {
      width: '100%',
    }
   }
  },
  myNavBarTransparent: {
   background: 'rgba(110, 47, 79, .1) !important',
   position: 'fixed',
   transition: 'all 1s ease-out'
  },
  myNavBar: {
    background: 'rgba(110, 47, 79, 1) !important',
    position: 'fixed',
    transition: 'all 1s ease-out'
   }


});

function NavBar() {
 const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { x, y } = useScrollPosition();



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
 const classes = useStyles();
  return (
   <AppBar position="fixed" className={y<600?classes.myNavBarTransparent:classes.myNavBar}>
   <Container maxWidth="xl">
     <Toolbar disableGutters>
       
       <Typography
         variant="h6"
         noWrap
         component="a"
         href="/"
         sx={{
           mr: 2,
           display: { xs: 'none', md: 'flex' },
           fontFamily: 'Monoton',
           fontWeight: 500,
           letterSpacing: '.3rem',
           color: 'inherit',
           textDecoration: 'none',
           fontSize: '30px',
         }}
       >
         kickIT
       </Typography>

       <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
         <IconButton
           size="large"
           aria-label="account of current user"
           aria-controls="menu-appbar"
           aria-haspopup="true"
           onClick={handleOpenNavMenu}
           color="inherit"
         >
           <MenuIcon />
         </IconButton>
         <Menu
           id="menu-appbar"
           anchorEl={anchorElNav}
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'left',
           }}
           keepMounted
           transformOrigin={{
             vertical: 'top',
             horizontal: 'left',
           }}
           open={Boolean(anchorElNav)}
           onClose={handleCloseNavMenu}
           sx={{
             display: { xs: 'block', md: 'none' },
           }}
         >
           {pages.map((page,i) => (
             
              <MenuItem key={page} onClick={handleCloseNavMenu}>
               <Typography textAlign="center">
               <Link to={routes[i]} className={classes.myLinkV2}>{page}</Link>
               </Typography>
              </MenuItem>
             
           ))}
         </Menu>
       </Box>
     
       <Typography
         variant="h5"
         noWrap
         component="a"
         href=""
         sx={{
           mr: 2,
           display: { xs: 'flex', md: 'none' },
           flexGrow: 1,
           fontFamily: 'Monoton',
           fontWeight: 700,
           fontSize: '28px',
           letterSpacing: '.3rem',
           color: 'inherit',
           textDecoration: 'none',
         }}
       >
         kickIT
       </Typography>
        
       <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
         {pages.map((page,i) => (
          
           <span onClick={handleCloseNavMenu}
              
           >
            <Link to={routes[i]} className={classes.myLink}>
             {page}
             </Link>
           </span>
           
         ))}
       </Box>

        
     </Toolbar>
   </Container>
 </AppBar>
  );
}

export default NavBar;
