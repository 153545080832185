import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Style from './Style';

import Details from './Details';
import { TextField } from '@mui/material';
import { useFuzzy } from 'react-use-fuzzy';
//import ada from './ada.html'
import logo from './images/platform_logo.svg';
import bmrstyles from './bmrstyles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GridOnIcon from '@mui/icons-material/GridOn';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import NumbersIcon from '@mui/icons-material/Numbers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/List';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { styled, useTheme } from '@mui/material/styles';

const qtypes = ['Radio', 'Checkbox', 'Select', 'Text', 'Number', 'Grid', 'Stack', 'All'];
const icons = [<RadioButtonCheckedIcon/>, <CheckBoxIcon/>, <ArrowDropDownIcon/>, <TextFieldsIcon/>, <NumbersIcon/>, <GridOnIcon/>, <ListIcon/>, <AllInclusiveIcon/>];

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const useStyles = makeStyles({
  main_header: {
    background: '#1d242d',

    color: "white",
    padding: '10px',
    paddingTop: '90px'
  },
  styles_holder: {
    marginTop: '20px',
    background: 'rgba(0,0,0,.9)'
  },
  header_title: {
    display: "block",
    verticalAlign: 'center',
    textAlign: "left",
    float: 'right',
    width: '100%',
    height: '100%',
    lineHeight: '75px',
    fontSize: '30px'
  },
  txtfield: {
    background: 'white'
  }
})



const drawerWidth = 240;

const  All = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [searchStr, setSearchStr] = useState(null);
  const [open, setOpen] = useState(false);
  const [qtype_filter, setQTypeFilter] = useState(null); 
  const [filtered_styles, setFilteredStyles] = useState(bmrstyles);

  const { result, searchString, search } = useFuzzy(filtered_styles, {
    keys: ['name'],
  });

  useEffect(()=>{
    console.log(result);
  },[result])

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const filterByType = (qtype) => {
    if(qtype){
      console.log(qtype);
      let fstyles = bmrstyles.filter(s=>s.scope.includes(qtype));
      console.log(fstyles);
      setFilteredStyles(fstyles);
    }else{
      setFilteredStyles(bmrstyles);
    }
    
  }

  return (
    <>
      <div className={classes.main_header} >
        <Grid container
  alignItems="center"
  justifyContent="center">
          
          <Grid item xs={12}>
          <span className={classes.header_title}>Bright MR - Custom Styles </span>
          </Grid>
        </Grid>

      </div>
      
      <div className={classes.styles_holder}>
        <Grid container spacing={3}>
          {result.map((s, inds) => {
            return (
              <Grid item md={4}>
                <Style style={s} onSelect={()=>{setSelectedStyle(s)}}/>
              </Grid>
            )
          })}
        </Grid>
      </div>
      {selectedStyle && 
        <Details style={selectedStyle} onClose={()=>{setSelectedStyle(null)}}/>
      }

    </>
  );
}

export default All;
