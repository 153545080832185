import google from './images/google.PNG';
import audio from './images/audio.PNG';
import accessibility from './images/accessibility.PNG';
import prf from './images/prf.PNG';
import repdata from './images/repdata.PNG';
import ag from './images/ag_template.PNG';
import guidelines from './images/guidelines.PNG';
import gender_image from './images/Q2_Q3_screenshot.PNG'
import map_image from './images/Q4_screenshot.PNG'
import atm_image from './images/Q5_screenshot.PNG'
import cardsort_image from './images/Q6_screenshot.PNG'
import sliderpoints_image from './images/Q10_screenshot.PNG'
import hottext_image from './images/Q12_screenshot.PNG'
import dials_image from './images/Q15_desktop_screenshot.PNG'
import scratchcard_image from './images/Scratchcard_screenshot.PNG'
import atmtable_image from './images/Q29_screenshot.PNG'
import atmrating_image from './images/Q30_screenshot.PNG'
import autosum_image from './images/Q31_screenshot.PNG'
import ranksort_image from './images/Q33_screenshot.PNG'
import slidernumber_image from './images/Q34_screenshot.PNG'
import starrating_image from './images/Q35_screenshot.PNG'
import autocomplete_image from './images/Q36_screenshot.PNG'
import piechart_image from './images/Q37_screenshot.PNG'
import emojiscale_image from './images/Q42_screenshot.JPG'
import barchart_image from './images/Q44_screenshot.PNG'
import activityrank_image from './images/activity_rank.PNG'
import mdiff_image from './images/mdiff.PNG'
import { dialogClasses } from '@mui/material';


const bmrstyles = [
 {
   name: 'Interactive Dynamic One-Page Questions',
   location: 'https://bright.decipherinc.com/survey/lib/local/prf/v1',
   version: '1',
   date_created: '21.02.2022',
   date_updated: '21.02.2022',
   img: prf,
   description: "Custom patient charts. Dynamic validation, show and hide effects.",
   platform: true,
   details: ` `,
   scope: ["Stack"]
 },
 
 {
   name: 'AUDIO responses',
   location: 'https://bright.decipherinc.com/survey/lib/local/bmraudio/v1',
   version: '1',
   date_created: '21.02.2022',
   date_updated: '21.02.2022',
   img: audio,
   description: `Voice recording and automated transcription options.`,
   platform: true,
   details: `
   TO PROGRAMMER:

This AUDIO question setup allows submitting audio responses, stores them on the cloud and returns several OUTPUTS: 

1. The file url
2. The folder url
3. The date of submission
4. The transcript of the audio file [optional]

Please be aware that it relies on several resource tags containing default texts, that you can customize, based on the client preferences. They are later used as values for the style attributes.

All the ATTRIBUTES are listed below:

[bmr_audio:RID]- Holds the respondent ID, please pass an unique one, that can later be used for matching recording to respondent. Used as part of the resulting file name.
[bmr_audio:QLAB] - Holds the question label. Please be cautios once using the setup in loops and make sure you pass the QLAB dynamically. Used as part of the resulting file name.
[bmr_audio:PRIVACY] - Holds a resource/text for the privacy agreement.
[bmr_audio:INSTR] - Holds a resource/text with the question instructions /how should the respondent record and submit data /.
[bmr_audio:ERR_SAFARI] - Holds an error message, for iOS users using Chrome browser.
[bmr_audio:ERR_DEF] - Holds an error message, if all other browser checks fail.
[bmr_audio:LANG] - Holds the language code for transcription [OPTIONAL] (BCP-47). All available codes are available at: <a href="https://surveys.globaltestmarket.com/survey/lsh/v1/lp/ams/bmr/bmraudio/v1/transcription_langs.html" target="_blank"/>
[bmr_audio:TRANSCRIBE] - Set to Y/N to enable/ disable transcription.
[bmr_audio:START_REC_INIT_TEXT] - Holds the text displayed in the "Start" button.
[bmr_audio:START_REC_REP_TEXT] - Holds the text displayed in the "Record again" button.
[bmr_audio:PLAY_REC_TEXT] - Holds the text displayed in the "Play recording" button.
[bmr_audio:STOP_REC_TEXT] - Holds the text displayed in the "Stop recording" button.
[bmr_audio:KEY] - The key should match the one you added once creating the Audio Project in <a href="https://platform.bright-mr.com/" target="_blank">Bright Platform</a>.
[bmr_audio:TIME_REMAINING_TEXT] - Holds the remaining seconds text.
[bmr_audio:MAX_LENGTH] - Holds the max length of the recording (in seconds) (shorter amounts are recommended).
[bmr_audio:STOP_PLAYBACK_TEXT] - Holds the Stop Replay text.
[bmr_audio:BEFORE_SUBMISSION_TEXT] - Holds the text shown after transcription, before final submission of the answer.
[bmr_audio:CONFIRM_SUBMIT_TEXT] - Holds the text to be shown next to the checkbox for submitting the audio for processing.
  `,
  scope: ["Text"]
 },
 {
   name: 'Google Maps',
   location: 'https://bright.decipherinc.com/survey/lib/local/googlepins/v1/index.html',
   version: '1',
   date_created: '21.02.2022',
   date_updated: '21.02.2022',
   img: google,
   description: "Uses the respondent's location to show nearby pins and to ask for input",
   platform: true,
   scope: ["Text"]
 },
{
 name: 'Stylish arch, rating type question',
 location: 'https://bright.decipherinc.com/survey/selfserve/53b/stylesDemo?start-at=Q15_desktop',
 version: '',
 date_created: '26.05.2022',
 date_updated: '26.05.2022',
 img: dials_image,
 description: 'Overlays standard radio question so that it appears rendered as a dial.',
 platform: false,
 details: ``,
 scope:  ['Radio']
},
{
 name: 'Scratch Card',
 location: 'https://bright.decipherinc.com/survey/selfserve/53b/stylesDemo?start-at=ScratchCard',
 version: '1',
 date_created: '26.05.2022',
 date_updated: '26.05.2022',
 img: scratchcard_image,
 description: 'Allows showing interactive scratchcard component in the question. Useful for interactive, quiz-like surveys',
 platform: false,
 details: ``,
 scope:  ['Radio','Checkbox']
},
{
 name: 'Customized Grid Button Select',
 location: 'https://bright.decipherinc.com/survey/selfserve/53b/stylesDemo?start-at=Q30',
 version: '1',
 date_created: '26.05.2022',
 date_updated: '26.05.2022',
 img: atmrating_image,
 description: 'Renders 2D questions in form of button grid, where each (row X col) combination is individual selectable area with additional visual effects',
 platform: false,
 details: ``,
 scope:  ['Radio']
},
{
 name: 'PieChart',
 location: 'https://bright.decipherinc.com/survey/selfserve/53b/stylesDemo?start-at=Q37',
 version: '1',
 date_created: '26.05.2022',
 date_updated: '26.05.2022',
 img: piechart_image,
 description: 'Renders a multiline number question as multiple sliders and a piechart next to them, representing the current allocation of predefined sum across the given options.',
 platform: false,
 details: ``,
 scope:  ['Number']
},
{
 name: 'Emoji Scale',
 location: 'https://bright.decipherinc.com/survey/selfserve/53b/stylesDemo?start-at=Q42',
 version: '1',
 date_created: '26.05.2022',
 date_updated: '26.05.2022',
 img: emojiscale_image,
 description: 'Renders a radio question as a scale, with smiley faces. Usefull for hate-love scales. The images can be overwriten.',
 platform: false,
 details: ``,
 scope:  ['Radio']
},
{
 name: 'BarChart',
 location: 'https://bright.decipherinc.com/survey/selfserve/53b/stylesDemo?start-at=Q44',
 version: '1',
 date_created: '26.05.2022',
 date_updated: '26.05.2022',
 img: barchart_image,
 description: 'Renders a barchart below a standard number multi-row question. Usefull for visually representing the differences between the given allocations per option. ',
 platform: false,
 details: ``,
 scope:  ['Number']
},
]


const templates = [{
  name: 'Repdata Template /*Change name*/',
  location: 'https://bright.decipherinc.com/survey/lib/local/rep_data/v2',
  version: '2',
  date_created: '21.02.2022',
  date_updated: '21.02.2022',
  img: repdata,
  description: "Custom template, with ready to use validations. TBD: Get only the 'interesting' questions",
  platform: false,
  details: ` `,
  scope: ["Stack"]
},
{
  name: 'AG Template /*Change name*/',
  location: 'https://bright.decipherinc.com/survey/lib/local/ag_template/v3',
  version: '3',
  date_created: '21.02.2022',
  date_updated: '21.02.2022',
  img: ag,
  description: "Custom template, with ready to use validations. TBD: Get only the 'interesting' questions",
  platform: false,
  details: ` `,
  scope: ["Stack"]
}]
export default bmrstyles;