import { makeStyles } from '@mui/styles';

import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, Vector2 } from '@react-three/fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Section from '../Section';
import * as THREE from 'three';
import Grid from '@mui/material/Grid';
import TechCard from '../components/TechCard'
import IvoCard from '../components/IvoCard'

import bmr_platform from '../images/bmr_platform.PNG';
import bmr_platform_logged from '../images/bmr_platform_logged.PNG';

import forsta_custom from '../images/forsta_custom.PNG';
import wtm_task from '../images/wtm_task.PNG';
import wtm_projects from '../images/wtm_projects.PNG';
import eg from '../images/eg.PNG';

import forsta_custom_rounded from '../images/forsta_custom_rounded.png';
import platform_logged_rounded from '../images/platform_logged_rounded.png';
import wtm_rounded from '../images/wtm_rounded.png';
import eg_rounded from '../images/eg_rounded.png';

import dsm_1 from '../images/dsm_1.PNG';
import dsm_projects from '../images/dsm_projects.PNG';
import eg_fest from '../images/eg_fest.PNG';
import eg_spotify from '../images/eg_spotify.PNG';
import platform_biling from '../images/platform_biling.PNG';
import tc_1 from '../images/tc_1.PNG';
import mdiff from '../images/mdiff.PNG';
import mdiff_2 from '../images/mdiff_2.PNG';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


const images = [
  {
    original: bmr_platform,
    thumbnail: bmr_platform
  },
  {
    original: bmr_platform_logged,
    thumbnail: bmr_platform_logged
  },
  {
    original: forsta_custom,
    thumbnail: forsta_custom
  },
  {
    original: wtm_task,
    thumbnail: wtm_task
  },
  {
    original: wtm_projects,
    thumbnail: wtm_projects
  },
  {
    original: dsm_1,
    thumbnail: dsm_1
  },
  {
    original: dsm_projects,
    thumbnail: dsm_projects
  },
  {
    original: platform_biling,
    thumbnail: platform_biling
  },
  {
    original: tc_1,
    thumbnail: tc_1
  },
  {
    original: mdiff,
    thumbnail: mdiff
  },
  {
    original: mdiff_2,
    thumbnail: mdiff_2
  },

  {
    original: eg_fest,
    thumbnail: eg_fest
  },
  {
    original: eg_spotify,
    thumbnail: eg_spotify
  },
  {
    original: eg,
    thumbnail: eg
  }
];


function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => { mesh.current.rotation.x += 0.01; mesh.current.rotation.y += 0.01; mesh.current.rotation.z += 0.01; })
  // Return view, these are regular three.js elements expressed in JSX


  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[2, 2, 2]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />

    </mesh>
  )
}

const useStyles = makeStyles({

  galeryHolder: {
    width: '80vw',
    padding: '10vw',
    fontSize: '40px',
    textAlign: "center",
    color: 'white'
  },
  gridContainer: {
    width: '100%',
    paddingTop: '150px'
  }


});

function Projects() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={classes.app}>
      <div className={classes.gridContainer}>
        <Grid container spacing={5} justifyContent="start" alignItems="start">

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <IvoCard header={'Task Tracking (internal only)'} img={wtm_rounded} num={2}>
              <div>
                One of the most challenging projects we’ve been working on was the task tracking one, we did internally for Bright MR.
                It’s a multi-user platform, built in Django and using corporate-scale MySQL database running on AWS. In order to keep track of projects,
                tasks and users we ended up using over 50 tables, keeping records for teams, users, projects, tasks. There’s a handy admin page as well,
                that gives specific users the ability to run many different types of reports, with custom filters and time spans. On top of it, we are extensively
                using cron jobs for automating daily, weekly and monthly reports. <br />
                <br />
                All the billing stats as well as the salary exports are generated based on the data that we store, aggregate and manipulate. <br />
                <br />
                For each project we have a so called client link, where a real time stats can be tracked.
              </div>
            </IvoCard>
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={3}>
            <IvoCard header={'BMR Platform'} img={platform_logged_rounded} num={3}>
              (https://platform.bright-mr.com/) - during the last couple of years we invested a lot in order to bring our industry knowledge to the end clients,
              giving them ability to integrate our custom developed APIs directly in their projects. We did this by building another one-of-a-kind platform, with multiple
              companies support, user management and granting privileges and access to different tools and solutions for the MR businesses. Most of them can be seen in our
              RDI page (https://rdi.bright-research.com/)<br />
              <br />
              <ol>
                <li><b>Audio Responses</b> - a tool that offers recording an audio response rather than typing in answers, with the ability for post-processing it and transcribing the input to a text, depending on a provided target language.</li>
                <li><b>Accessibility Stack</b> - set of tools for disabled respondents, that offers them the ability to fill in the most common question types by only using the keyboard. WADA compliant!</li>
                <li><b>Google Pins</b> - offers the respondents the ability to answer geo-location questions, directly marking a place. A consent is required.</li>
                <li><b>Diary survey Manager</b> (https://rdi.bright-research.com/mr-solutions/bright-diary-survey-manager/) - a set of tools, including a respondent app that receives real time messages and notifications when a specific survey becomes active, or a new section has been enabled. Uses a Firebase infrastructure, with its innovative real time database. Deployed for all major mobile platforms (Android, iOS)</li>
                <li><b>Automated Gibberish Inspector</b> - one of the most innovative tools in our stack, that iterates over text inputs and automatically marks the meaningful texts. Uses pretrained AI algorithms and databases to compare the texts with.</li>
                <li><b>MaxDiff Designs and Analisys</b> - strictly a Marketing Research tool, for building custom testing designs and paths. It uses ML for simply an optimisation tasks, building well balanced subsets of items, so that the set of answers can then be interpolated and a regular scale can be produced.</li>
              </ol>
            </IvoCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <IvoCard header={'Customisations over regular Forsta Surveys'} img={forsta_custom_rounded} num={4}>
              One of the most common tasks that we have. The main idea is to force JS and custom styles over the regular styles and layout of a given platform (Forsta in our case). Dealing with XML schemas and rendition rules as well as injection of custom JS in order to bring life and dynamics to the regular questions.
            </IvoCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <IvoCard header={'EvgenyGenchev.net'} img={eg_rounded} num={4}>
              Our latest project was to transfer the site of the famous Bulgarian pianist, actor, composer and writer Evgeny Genchev from WordPress to the GCP tech stack, keeping the design that was made by his own company 2927, as close to the original one.
              It was definitely something new for us, as we had to deal with transferring domains to a totally different infrastructure, while the usual CMS should have been untouched in terms of functionalities.
              YouTube and Spotify integrations were another interesting part to play around with. <br />
              <br />
              During the development, the idea of creating a Festival page emerged, so that's where we used technologies like PayPal and Stripe in order to enable online payments and to generate personalised tickets.
            </IvoCard>
          </Grid>

        </Grid>
      </div>
      <div className={classes.galeryHolder}>
        There are some screenshots from our recent projects.
        <ImageGallery
          items={images}

          showBullets={true}
          showIndex={true}
          showThumbnails={false}
          lazyLoad={true}
          showPlayButton={false}

        />
      </div>

    </div>
  );
}

export default Projects;





















